<template>
  <div class="photos--section" v-if="photos">
    <div class="photo--item" v-for="(photo, index) in photos" :key="index">
      <a :href="photo" target="_blank">
        <img :src="photo" />
      </a>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'simple-photo-preview',
  mixins: [HelperMixin],
  props: ['photos'],
  methods: {},
};
</script>

<style scoped></style>
